<template>
    <NimbusInputWrapper
        v-bind="$props" ref="wrapper" @right-icon-click="(e) => $emit('rightIconClick', e)" right-icon-out
        left-icon-disabled
    >
        <template #input>
            <!-- v-tooltip directive throws error when given a null value -->
            <span v-if="tooltip" v-tooltip="tooltip">
                <Calendar
                    v-bind="$attrs"
                    @update:modelValue="updateInput"
                    :inputClass="[inputBorderColorClass, inputClass, 'niw-dynamic-border', inputBorderColorClass ? '' : 'border-right-none']"
                    :modelValue="modelValue"
                    class="w-full"
                    :dateFormat="dateFormat"
                    :showIcon="showIcon"
                    icon="pi pi-calendar"
                    :selection-mode="selectionMode"
                    :view="view"
                />
            </span>

            <InputText
                v-else-if="dateFormat === 'M yy'"
                v-bind="$attrs"
                :model-value="modelValue"
                @update:modelValue="updateInput"
            />

            <!-- render component with no v-tooltip directive if tooltip is null -->
            <Calendar
                v-else
                ref="nimbusCalendarRef"
                v-bind="$attrs"
                :inputClass="[inputBorderColorClass, inputClass, 'niw-dynamic-border', inputBorderColorClass ? '' : 'border-right-none']"
                :modelValue="modelValue"
                class="w-full"
                :dateFormat="dateFormat"
                :showIcon="showIcon"
                icon="pi pi-calendar"
                :selection-mode="selectionMode"
                :view="view"
                @update:modelValue="updateInput"
            />
        </template>
        <template #bottom>
            <slot />
        </template>
    </NimbusInputWrapper>
</template>

<script>
import Calendar from 'primevue/calendar';
import NimbusInputWrapper from '@/components/nimbus/NimbusInputWrapper';

import { nextTick, onBeforeUnmount, onMounted, ref } from 'vue';

export default {
    name: 'NimbusCalendar',
    components: {
        NimbusInputWrapper,
        Calendar,
    },
    props: {
        inputClass: {
            type: String,
        },
        modelValue: {
            type: [String, Date],
        },
        tooltip: {
            type: String,
            default: null,
        },
        dateFormat: {
            type: String,
            default: 'd M yy',
        },
        showIcon: {
            type: Boolean,
            default: true,
        },
        selectionMode: {
            type: String,
            default: 'single',
        },
        view: {
            type: String,
            default: 'date', // month | year | date
        },
    },
    emits: ['rightIconClick', 'update:modelValue'],
    setup(props, { emit }) {
        const wrapper = ref(null);
        const inputBorderColorClass = ref(null);
        const nimbusCalendarRef = ref(null);

        /**
         * Handle manual input of date
         *
         * Note:
         *  The native @keydown.enter is not being triggered by PrimeVue's calendar, so need to manually add an event
         *  listener for it.
         */
        const handleEnterKey = (event) => {
            const inputValue = event.target.value.trim();
            let dates = [];

            if (inputValue.includes('-')) {
                // Split input on " - " to handle date ranges
                const [startDateInput, endDateInput] = inputValue.split(' - ').map(dateStr => dateStr.trim());

                const
                    startDate = new Date(startDateInput),
                    endDate = new Date(endDateInput);

                if (!isNaN(startDate.getTime()) && !isNaN(endDate.getTime())) {
                    dates = [startDate, endDate];
                }
            } else {
                const singleDate = new Date(inputValue);
                if (!isNaN(singleDate.getTime())) {
                    dates = singleDate;
                }
            }

            if (dates.length || dates instanceof Date) {
                emit('update:modelValue', dates);

                // Hide the calendar popup manually
                const datepicker = document.querySelector('.p-datepicker.p-component');
                if (datepicker) datepicker.style.display = 'none';
            } else {
                console.error('Invalid date format:', inputValue);
            }
        };

        onMounted(async () => {
            inputBorderColorClass.value = wrapper.value.inputBorderColorClass;

            // Wait for the next tick to ensure the DOM is updated
            await nextTick();

            // Find the input element
            const inputElement = nimbusCalendarRef.value?.$el.querySelector('input');
            if (inputElement) {
                inputElement.addEventListener('keydown', (event) => {
                    if (event.key === 'Enter') {
                        handleEnterKey(event);
                    }
                });
                inputElement.addEventListener('input', () => {
                    document.querySelector('.p-datepicker.p-component').style.display = 'block';
                });
            }
        });

        // Clean up event listener when the component is unmounted
        onBeforeUnmount(() => {
            const inputElement = nimbusCalendarRef.value?.$el.querySelector('input');
            if (inputElement) {
                inputElement.removeEventListener('keydown', handleEnterKey);
                inputElement.removeEventListener('input', () => {
                    document.querySelector('.p-datepicker.p-component').style.display = 'block';
                });
            }
        });

        /**
         * Emit 'v-model:update' event for binding
         * @param event
         */
        const updateInput = (event) => {
            emit('update:modelValue', event);
        };

        return {
            /**
             * Data
             */
            inputBorderColorClass,
            wrapper,
            nimbusCalendarRef,

            /**
             * Function
             */
            updateInput,
        };
    },
};
</script>

<style>
.p-calendar .p-button,
.p-calendar .p-button:enabled:hover {
    background-color: #ffffff !important;
    border: 1px solid #ced4da;
    color: #000000;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-left: 0;
}
</style>
